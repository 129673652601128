'use strict';

var observer;

let observabledLazyBlockSelectorsConfig;
let observabledDeferedSelectorsConfig;
window.addEventListener('load', () => {
    observabledLazyBlockSelectorsConfig = {
        'favouriteExperiences': loadExperiences,
        'creatorExperiences': loadExperiences,
        'foodieExperiences': loadExperiences,
        'creatorsCarousel': initCreatorsCarousel,
        'advantagesCarousel': initAdvantagesCarousel,
        'hostsCarousel': loadHosts,
        'contentPageAdditionalCarousel': requestContentPages,
        'contentPageCarousel': requestContentPages,
        'reviewsCarousel': loadReviews,
        'reviewsCarouselAjax': loadReviewsAjax,
        'browseExperiences': initBrowseExperiencesGrid,
        'browseExperiencesHostSingle': initBrowseExperiencesHostSingleGrid,
        'browseExperiencesCreator': initBrowseExperiencesCreatorGrid,
        'browseArticles': initBrowseArticlesGrid,
        'freeVirtExperiencesFilters': initFreeVirtualExperiencesGrid,
        'experiencesForAllType': initExperiencesForAllType,
        'photosCarousel': initPhotosCarousel,
        'popularDestinationsCarousel': initPopularDestinationsCarousel,
        'popularDestinationsGridCarousel': initPopularDestinationsGridCarousel,
        'otherCreatorsCarousel': initOtherCreatorsCarousel,
        // 'virtualGuidesExperiencesCarousel': initVirtualGuidesExperiencesCarousel,
        'parallaxBox': initParallax,
        'parallaxBoxSeoPage': initParallax,
        'parallaxBoxCountryPage': initParallax,
        'reviewsSection': initReviewsSection,
        'reviewSlider': initNewReviewsCarouselLazy,
        'meetHostCarousel': initMeetHostCarouselLazy,
        'experiencesForEveryone': initExperiencesForEveryone,
        'featuredHosts': initFeaturedHosts
    };

    observabledDeferedSelectorsConfig = {
        'facesAnimationBlock': initFacesAnimation,
    };

    dynamicFeaturedExperiences.forEach(function (sectionId) {
        observabledLazyBlockSelectorsConfig[sectionId] = loadExperiences;
    });

    createObserver();
}, false);

function createObserver() {
    observer = new IntersectionObserver(handleIntersect, {});
    for (const index in observabledLazyBlockSelectorsConfig) {
        addToObserver(index);
    }

    for (const index in observabledDeferedSelectorsConfig) {
        addToObserver(index);
    }
}

function addToObserver(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
        observer.observe(element);
    }
}

function handleIntersect(entries, observer) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            for (const index in observabledLazyBlockSelectorsConfig) {
                if (index === entry.target.id && observabledLazyBlockSelectorsConfig.hasOwnProperty(index)) {
                    loadLazyAssets(entry.target.id, observabledLazyBlockSelectorsConfig[index], { 'selectorName': entry.target.id });
                }
            }

            for (const index in observabledDeferedSelectorsConfig) {
                if (index === entry.target.id && observabledDeferedSelectorsConfig.hasOwnProperty(index)) {
                    let func = observabledDeferedSelectorsConfig[index];
                    if (typeof func === 'function') {
                        func();
                        initParallax();
                    }
                }
            }

            observer.unobserve(document.getElementById(entry.target.id));
        }
    });
}