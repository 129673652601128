'use strict';

/**
 * @param {obj} experienceCard jQuery object
 * @param {number} quantityAdultInt
 * @param {number} quantityChildrenInt
 * @returns {obj}
 */
function updateExperienceCardPrice(experienceCard, quantityAdultInt, quantityChildrenInt = 0) {
    if (typeof experienceCard.attr('data-isvirtual') !== undefined && parseInt(experienceCard.attr('data-isvirtual'), 10)) {
        return experienceCard;
    }

    const duration = parseFloat(experienceCard.attr('data-durationhours'));
    const pp = parseFloat(experienceCard.attr('data-pp'));
    const pps = parseFloat(experienceCard.attr('data-pps'));
    const pep = parseFloat(experienceCard.attr('data-pep'));
    const totalFixedUpSellPrice = parseFloat(experienceCard.attr('data-totalfixedupsellprice'));
    const totalUpSellPriceForAdult = parseFloat(experienceCard.attr('data-totalupsellpriceforadult'));
    const totalUpSellPriceForChild = parseFloat(experienceCard.attr('data-totalupsellpriceforchild'));
    const extraPerHour = parseFloat(experienceCard.attr('data-extraperhour'));
    const defaultDuration = parseFloat(experienceCard.attr('data-defaultduration'));

    let newPrice = pps + (quantityAdultInt - 2) * pep;

    if (quantityAdultInt === 1) {
        newPrice = pp;
    } else if (quantityAdultInt === 2) {
        newPrice = pps;
    }

    if (duration > defaultDuration) {
        newPrice += (duration - defaultDuration) * extraPerHour;
    }

    newPrice += totalFixedUpSellPrice;
    newPrice += totalUpSellPriceForAdult * quantityAdultInt;
    newPrice += totalUpSellPriceForChild * quantityChildrenInt;

    experienceCard.find('[data-name=price_text]').text(experienceCard.attr('data-currencysymbol') + (newPrice / quantityAdultInt).toFixed(2) + ' per person');

    return experienceCard;
}

function getQttyAdults() {
    let adultsQtty = 2; //2 should be equal with a default value in BE
    if (typeof getCookie === 'function' && typeof quantityAdultsCookieName !== 'undefined' && typeof getCookie(quantityAdultsCookieName) !== 'undefined') {
        adultsQtty = getCookie(quantityAdultsCookieName);
    } else if ($('.js-search-tool .people-area').length && $('.js-search-tool .people-area').find('.adults').find('.js-quantity-search-tool').length && typeof $('.js-search-tool .people-area').find('.adults').find('.js-quantity-search-tool').data('quantity') !== 'undefined') {
        adultsQtty = $('.js-search-tool .people-area').find('.adults').find('.js-quantity-search-tool').data('quantity');
    }

    return parseInt(adultsQtty, 10);
}

function getQttyChildren() {
    let childrenQtty = 0; //0 should be equal with a default value in BE
    if (typeof getCookie === 'function' && typeof quantityChildCookieName !== 'undefined' && typeof getCookie(quantityChildCookieName) !== 'undefined') {
        childrenQtty = getCookie(quantityChildCookieName);
    } else if ($('.js-search-tool .people-area').length && $('.js-search-tool .people-area').find('.children').find('.js-quantity-search-tool').length && typeof $('.js-search-tool .people-area').find('.children').find('.js-quantity-search-tool').data('quantity') !== 'undefined') {
        childrenQtty = $('.js-search-tool .people-area').find('.children').find('.js-quantity-search-tool').data('quantity');
    }

    return parseInt(childrenQtty, 10);
}