'use strict';

function loadAsset(url, type, name, callback, callbackOptions) {
    let element;

    if (type === 'script') {
        loadedScriptsStatuses[name] = 'loading';
        element = document.createElement('script');
        element.type = 'text/javascript';
        element.src = url;
        element.nonce = nonceValue;

        if (element.readyState) {  //IE
            element.onreadystatechange = function () {
                if (element.readyState === 'loaded' || element.readyState === 'complete') {
                    element.onreadystatechange = null;
                    loadedScriptsStatuses[name] = 'loaded';
                    if (typeof callback === 'function') {
                        callback(callbackOptions);
                    }
                }
            };
        } else {  //Others
            element.onload = function () {
                loadedScriptsStatuses[name] = 'loaded';
                if (typeof callback === 'function') {
                    callback(callbackOptions);
                }
            };
        }
    } else if (type === 'style') {
        element = document.createElement('link');
        element.rel = 'stylesheet';
        element.href = url;

        if (typeof callback === 'function') {
            callback(callbackOptions);
        }
    } else {
        return false;
    }

    document.getElementsByTagName('head')[0].appendChild(element);
}

function loadLazyAssets(name, callback, options) {
    let uploadedAssets = [];
    $.each(lazyLoadedAssets, function (key, value) {
        if (key === name) {
            let length = value.length;
            if (length) {
                $.each(value, function (i, data) {
                    let callbackFn = '';
                    if (i === (length - 1)) {
                        callbackFn = callback;
                    }

                    uploadedAssets.push(data.url);
                    loadAsset(data.url, data.type, key, callbackFn, options);
                });
            } else {
                callback(options);
            }

            initParallax();
        }
    });

    if (uploadedAssets.length) {
        removeLazyLoadedAssetsDuplicates(uploadedAssets);
    }
}

function removeLazyLoadedAssetsDuplicates(uploadedAssets) {
    $.each(uploadedAssets, function (j, url) {
        $.each(lazyLoadedAssets, function (name, data) {
            $.each(data, function (i, value) {
                if (typeof value !== 'undefined' && value.url === url) {
                    lazyLoadedAssets[name].splice(i, 1);
                    removeLazyLoadedAssetsDuplicates(uploadedAssets);
                }
            });
        });
    });
}

let isSlickLoaded = function () {
    return (typeof $.fn.slick === 'function');
};

let loadedScriptsStatuses = [];

let initSlickSlider = function (callback) {
    if (isSlickLoaded()) {
        callback();
        return false;
    }

    if (
        typeof loadedScriptsStatuses['slick-css'] !== 'undefined' &&
        typeof loadedScriptsStatuses['slick-js'] !== 'undefined' &&
        loadedScriptsStatuses['slick-css'] === 'loaded' && loadedScriptsStatuses['slick-js'] === 'loaded'
    ) {
        callback();
    } else if (
        typeof loadedScriptsStatuses['slick-js'] === 'undefined' ||
        typeof loadedScriptsStatuses['slick-css'] === 'undefined' ||
        loadedScriptsStatuses['slick-js'] === 'notLoaded' ||
        loadedScriptsStatuses['slick-css'] === 'notLoaded'
    ) {
        loadAsset('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css', 'style', 'slick-css');
        loadAsset('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js', 'script', 'slick-js', callback);
    } else if (
        typeof loadedScriptsStatuses['slick-js'] !== 'undefined' &&
        typeof loadedScriptsStatuses['slick-css'] !== 'undefined' &&
        (loadedScriptsStatuses['slick-js'] === 'loading' || loadedScriptsStatuses['slick-css'] === 'loading')) {
        setTimeout(callback, 2000);
    } else {
        console.log('Cant resolve Slick Slider library');
    }
};

// ====================================

function loadExperiences(options) {
    const block = $('#' + options.selectorName);

    if (block.data('url').length) {
        $.ajax({
            url: block.data('url'),
            dataType: 'json',
            type: 'GET',
            success: function (response) {
                if (response.data && response.data.length) {
                    block.append(response.data);

                    setTimeout(function () {
                        new ExperienceCarouselLazy(options.selectorName).init();
                        recalculateExperienceCardsPrices();
                        initFavouriteExperiencesInSection($(block));
                    }, 1);
                }
            },

            error: function (response) {
                console.log(response);
            },
        });
    } else if ((!block.data('url') || !block.data('url').length) && block.children('.slider-item').length) {
        setTimeout(function () {
            new ExperienceCarouselLazy(options.selectorName).init();
            recalculateExperienceCardsPrices();
        }, 1);
    }
}

function recalculateExperienceCardsPrices() {
    if (typeof updateExperienceCardPrice === 'function' && typeof getQttyAdults === 'function' && typeof getQttyChildren === 'function' && $('.js-price-changing').length) {
        const qttyAdults = getQttyAdults();
        const qttyChildren = getQttyChildren();

        $('.js-price-changing').each(function () {
            updateExperienceCardPrice($(this), qttyAdults, qttyChildren);
        });
    }
}

function loadHosts(options) {
    const block = $('#' + options.selectorName);
    $.ajax({
        url: block.data('url'),
        dataType: 'json',
        type: 'GET',
        success: function (response) {
            if (response.data && response.data.length) {
                block.append(response.data);

                setTimeout(function () {
                    new HostCarouselLazy(options.selectorName).init();
                }, 1);

            }
        },

        error: function (response) {
            console.log(response);
        },
    });
}

function requestContentPages(options) {
    const block = $('#' + options.selectorName);
    $.ajax({
        url: block.data('url'),
        type: 'GET',
        dataType: 'json',
        success: function (response) {
            if (response.length) {
                new ContentPageCarouselLazy(options.selectorName).initCarousel(response);
            }
        },

        error: function (response) {
            console.log(response);
        },
    });
}

function loadReviews(options) {
    new ReviewsCarouselLazy(options.selectorName).init();
}

function loadReviewsAjax(options) {
    new AddReviewWidgetsLazy().init();
}

function initBrowseExperiencesGrid() {
    new SelectorInitiator().setObject(new LoadExperiences());
    new SelectorInitiator().setObject(new ProgressiveBar('.js-single-experience', '.experiences-section-container'));
    new SelectorInitiator().setObject(new Filters());

    new SelectorInitiator().runInit();
}

function initBrowseExperiencesCreatorGrid() {
    new SelectorInitiator().setObject(new LoadCreatorExperiences());
    new SelectorInitiator().setObject(new ProgressiveBar('.js-single-experience', '.experiences-section-container'));
    new SelectorInitiator().setObject(new CreatorFilters());

    new SelectorInitiator().runInit();
}

function initBrowseExperiencesHostSingleGrid() {
    if (isMobile) {
        initLoadHostSingleExperiences();
    } else {
        window.addEventListener('resize', function () {
            if (isMobile) {
                initLoadHostSingleExperiences();
            }
        });
    }
}

function initLoadHostSingleExperiences() {
    new SelectorInitiator().setObject(new LoadHostSingleExperiences());
    new SelectorInitiator().setObject(new ProgressiveBar('.js-single-experience', '.experiences-section-container'));

    new SelectorInitiator().runInit();
}

function initFreeVirtualExperiencesGrid() {
    new SelectorInitiator().setObject(new ChangeAppearanceEffectExperienceCards('.card-block-item.appearance-effect'));
    new SelectorInitiator().setObject(new ImageHeightVariable());
    new SelectorInitiator().setObject(new LoadExperiencesForExtendedWidget(
        new SelectorInitiator().getObjectBySelector('.image-height-variable')
    ));
    new SelectorInitiator().setObject(new ProgressiveBar('.js-single-experience', '#browseExperiencesCategoryPage'));
    new SelectorInitiator().setObject(new Filters());

    new SelectorInitiator().runInit();
}

function initBrowseArticlesGrid() {
    new SelectorInitiator().setObject(new LoadArticles());
    new SelectorInitiator().setObject(new ProgressiveBar('.js-single-article', '.travel-magazine-articles'));
    new SelectorInitiator().setObject(new ArticlesFilters());

    new SelectorInitiator().runInit();
}

function initFacesAnimation() {
    new SelectorInitiator().setObject(new FacesAnimation());

    new SelectorInitiator().runInit();
}

let parallaxTimeout;
function initParallax(time = 1000) {
    if ($('.parallax-window').length && !isMobile) {
        clearTimeout(parallaxTimeout);
        parallaxTimeout = setTimeout(function () {
            $(window).trigger('resize').trigger('scroll');
        }, time);
    }
}

function initExperiencesForEveryone(options) {
    new ExperiencesForEveryoneLazy(options.selectorName).init();
}

function initExperiencesForAllType(options) {
    new ExperiencesForALLTypeLazy(options.selectorName).init();
}

function initPhotosCarousel(options) {
    new PhotosCarouselLazy(options.selectorName).init();
}

function initPopularDestinationsCarousel(options) {
    new PopularDestinationsCarouselLazy(options.selectorName).init();
}

function initPopularDestinationsGridCarousel(options) {
    new PopularDestinationsGridCarouselLazy(options.selectorName).init();
}
function initOtherCreatorsCarousel(options) {
    new OtherCreatorsCarouselLazy(options.selectorName).init();
}
function initVirtualGuidesExperiencesCarousel(options) {
    new virtualGuidesExperiencesCarouselLazy(options.selectorName).init();
}

function initReviewsSection(options) {
    new ReviewsSectionLazy(options.selectorName).init();
}

function initCreatorsCarousel(options) {
    new CreatorsCarouselLazy(options.selectorName).init();
}
function initAdvantagesCarousel(options) {
    new AdvantagesCarouselLazy(options.selectorName).init();
}

function initNewReviewsCarouselLazy(options) {
    new NewReviewsCarouselLazy(options.selectorName).init();
}

function initMeetHostCarouselLazy(options) {
    new MeetHostCarouselLazy(options.selectorName).init();
}

function initFeaturedHosts(options) {
    new FeaturedHosts(options.selectorName).init();
}