const SelectorInitiator = (function() {
    let instance;

    function Singleton() {
        if (!instance) {
            instance = this;
        } else {
            return instance;
        }
    }

    Singleton.prototype.initedObjects = [];

    Singleton.prototype.objects = [];
    Singleton.prototype.setObject = function(object) {
        if (!object.hasOwnProperty('selector')) {
            console.error('SelectorInitiator: object should has an own property "selector"');
            return false;
        }
        if (typeof object.init !== 'function') {
            console.error('SelectorInitiator: object should has an own function "init()"');
            return false;
        }
        for (let key in this.objects) {
            if (this.objects[key].selector === object.selector) {
                console.error(
                    'SelectorInitiator: object with selector ' +
                        object.selector +
                        ' has been already set'
                );
                return false;
            }
        }
        this.objects.push(object);
    };

    Singleton.prototype.runInit = function() {
        for (let key in this.objects) {
            let domElement = document.querySelector(this.objects[key].selector);
            if (
                !this.initedObjects.includes(this.objects[key].selector) && typeof(domElement) !== 'undefined' && domElement !== null
            ) {
                this.objects[key].init();
                this.initedObjects.push(this.objects[key].selector);
            }
        }
    };

    Singleton.prototype.getObjectBySelector = function(selector) {
        for (let key in this.objects) {
            if (this.objects[key].selector === selector) {
                return this.objects[key];
            }
        }
    };

    Singleton.prototype.getObjects = function() {
        return this.objects;
    };

    return Singleton;
})();