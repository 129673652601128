'use strict';

const LocationsDropdownList = function() {
    this.selector = '.dropdown-list';

    this.init = function() {
        $('.countries-list .title').on('click', function () {
            $('.cities-list .list').css('visibility', 'hidden');
            switchElementVisibility($(this).parent().find('.list'));
        });

        $('.cities-list .title').on('click', function () {
            $('.countries-list .list').css('visibility', 'hidden');
            switchElementVisibility($(this).parent().find('.list'));
        });


        $(window).one('scroll', function() {
            document.addEventListener('click', event =>
                shouldCloseList(event) ? $('.dropdown-list .list').css('visibility', 'hidden').parent().find('.arrow-down').removeClass('up').addClass('down') : null
            );
        });
    };

    const switchElementVisibility = function(obj) {
        obj.css('visibility', (obj.css('visibility') !== 'hidden' ? 'hidden' : 'initial'));

        const arrowSign = obj.parent().find('.arrow-down');

        if (arrowSign && arrowSign.hasClass('down')) {
            arrowSign.removeClass('down').addClass('up');
        } else if (arrowSign && arrowSign.hasClass('up')) {
            arrowSign.removeClass('up').addClass('down');
        }
    };

    const shouldCloseList = function(event) {
        return !$(event.target).is('.dropdown-list .list') && !$(event.target).is('.dropdown-list .title') && !$(event.target).is('.dropdown-list .arrow-down');
    };
};

new SelectorInitiator().setObject(new LocationsDropdownList());
