'use strict';

/**
 * Scrolls to current element and if page will have dynamic children
 * elements (added or removed on scrolling) we also will be scrolled
 * to current element by calling the function recursively
 *
 * @param id {String}
 * @return void
 */
function scrollToElement(id) {
    $('.to-scroll').each(function() {
        $(this).on('click', function() {
            const headerHeight = $('.main-nav').outerHeight();

            $('body').removeClass('no-scroll');

            $('html, body').animate({
                easing: 'linear',
                scrollTop: ($(id).offset().top - headerHeight)
            }, 1000, function() {
                $('html, body').animate({
                    easing: 'linear',
                    scrollTop: ($(id).offset().top - headerHeight)
                }, 1);
            });
        });
    });
}

if (window.$ && $('#any_questions').length) {
    scrollToElement('#any_questions');
}

function getCookie(name) {
    let value = '; ' + document.cookie;
    let parts = value.split('; ' + name + '=');

    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}

window.onclick = function(event) {
    let modal = document.getElementById('mainPopup');

    if (event.target === modal) {
        closePopUp();
    }
};

const closePopUp = () => {
    let modal = document.getElementById('mainPopup');
    modal.style.display = 'none';

    let popupContent = document.getElementById('popupContent');
    if (popupContent) {
        popupContent.classList.remove(...popupContent.classList);
        popupContent.classList.add('popup-content');
    }

    document.getElementById('mainPopupBody').innerHTML = '';
};

const showPopupMessage = function (messageHtml) {
    document.getElementById('mainPopupBody').innerHTML = '<div class="popup-message">' + messageHtml + '</div>';
    let modal = document.getElementById('mainPopup');
    modal.style.display = 'initial';
};

$('.js-live-chat').on('click', function(event) {
    event.preventDefault();

    if (typeof zE !== `undefined`) {
        zE(`messenger`, `open`);
    }

    return false;
});