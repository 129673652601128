'use strict';

function cleanLoginErrorMessages(obj, borderColor) {
    let err = document.getElementById(obj.id + '-message');

    if (err.innerHTML !== '') {
        err.innerHTML = '';
        obj.style.borderColor = borderColor? borderColor : '#707070';
        obj.style.transition = 'border-color .5s';
    }
}

function getLengthLoginValidationErrorMessage(obj, params) {
    let minLengthValue = 2;
    if (!$.isEmptyObject(params) && 'min' in params) {
        minLengthValue = parseInt(params.min, 10);
    }

    let maxLengthValue = 50;
    if (!$.isEmptyObject(params) && 'max' in params) {
        maxLengthValue = parseInt(params.max, 10);
    }

    let value = $(obj).val();

    value = value.replace(/\s/g, '');
    let errorMessage = '';
    if (value.length < minLengthValue) {
        errorMessage = 'Field must be at least ' + minLengthValue + ' characters';
    }

    if (value.length > maxLengthValue) {
        errorMessage = 'Field must be not more than ' + maxLengthValue + ' characters';
    }

    return errorMessage;
};

function getEmailLoginValidationErrorMessage(obj) {
    const regExForEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const email = $(obj).val();
    let errorMessage = '';

    if ((!regExForEmail.test(email)) || (email.length < 6)) {
        errorMessage = 'Enter valid Email';
    }

    return errorMessage;
};

function validateLoginField(obj, callback, params) {
    let errField = document.getElementById(obj.id + '-message');
    let validationParams = {};

    if (!$.isEmptyObject(params)) {
        validationParams = params;
    }

    if (typeof(callback) === 'function' && callback(obj, validationParams).length > 0 ) {

        $(errField).hide().html(callback(obj, validationParams)).fadeIn(1500);
        obj.style.borderColor = '#e84042';
        obj.style.transition = 'border-color .5s';

        return false;
    }

    return true;
}

function validateLoginIdentical(password, params) {
    let errorMessage = '';

    if ($(password).val() !== $(params.linkField).val()) {
        errorMessage = 'The password confirmation does not match';
    }

    return errorMessage;
}
function showJSValidationError(selector = '.login-form-alert-danger') {
    let showError = false;

    if (showError) {
        return false;
    }
    $(selector).each(function () {

        if ($(this).text().length) {
            showError = true;
        }
    });
    return showError;
}
function clearAllError(){
    $('.login-form-alert-danger').each(function () {
        $(this).text('');
    });
    $('.form-control').each(function () {
        $(this).css('border-color', '#28306c');
    });
}
function validatePassword(obj, params) {
    let password = $(obj).val();
    let minLengthValue;
    let maxLengthValue;

    if (!$.isEmptyObject(params) && 'min' in params) {
        minLengthValue = parseInt(params.min, 10);
    }

    if (!$.isEmptyObject(params) && 'max' in params) {
        maxLengthValue = parseInt(params.max, 10);
    }
    const passwordRegex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()\\-=_+[\\]{};':"\\\\|,.<>/?]).{${minLengthValue},${maxLengthValue}}$`);

    let errorMessage = '';
    if (!passwordRegex.test(password)) {
        errorMessage = `Password must contains ${minLengthValue}-${maxLengthValue} characters, at least one uppercase letter, one lowercase letter, one number and any special character`;
    }

    return errorMessage;
}
