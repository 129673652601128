'use strict';

const Login = function() {
    this.selector = '.loginButton';
    this.init = function () {

        $(this.selector).on('click', function (event, allowredirect) {

            $(this).attr('data-allowredirect', (typeof allowredirect !== 'undefined') ? allowredirect : 'true');

            $.ajax({
                url: '/api/get-login-form?nc=' + (typeof nonce !== 'undefined' ? nonce : ''),
                type: 'GET',
                dataType: 'json',
                success: function (response) {
                    if (response.redirectUrl !== undefined) {
                        window.location.href = response.redirectUrl;
                    }

                    if (typeof response.data !== 'undefined') {
                        $('#mainPopupBody').html(response.data);
                        $('#mainPopup').css('display', 'block');
                        $('#popupContent').addClass('login-desktop').addClass('slide-in');
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.error(arguments);
                    window.location.replace('/login');
                },
            }).then(function () {
                if (typeof showLaterButton !== 'undefined' && showLaterButton) {
                    $('.later-button-holder').css('display', 'block');
                }
            });
        });

        if (typeof isLoginRequired !== 'undefined' && isLoginRequired) {
            $(this.selector).trigger('click', ['false']);
        }
    };
};

new SelectorInitiator().setObject(new Login());